


























import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class DateCalendarIcon extends Vue {
  /**
   * `date` can be a string or an actual date object.
   */
  @Prop({ required: true }) date!: string;
  @Prop({ required: false, default: false }) small!: boolean;

  get dateObj(): Date {
    return new Date(this.$props.date);
  }

  get month(): string {
    return this.dateObj.toLocaleString(undefined, { month: "short" });
  }

  get day(): string {
    return this.dateObj.toLocaleString(undefined, { day: "numeric" });
  }
  get smallStyle(): string {
    return this.small ? "1.2" : "2";
  }
}
