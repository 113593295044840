


























import { Prop, Component, Vue, Ref, Watch } from "vue-property-decorator";
import BlurhashImg from "./BlurhashImg.vue";

@Component({
  components: {
    BlurhashImg,
  },
})
export default class LazyImage extends Vue {
  @Prop({ type: String, required: true }) src!: string;
  @Prop({ type: String, required: false, default: null }) blurhash!: string;
  @Prop({ type: Number, default: 1 }) width!: number;
  @Prop({ type: Number, default: 1 }) height!: number;
  @Prop({ type: Boolean, default: false }) rounded!: boolean;

  inheritAttrs = false;
  isLoaded = false;

  observer!: IntersectionObserver;

  @Ref("wrapper") readonly wrapper!: any;
  @Ref("image") image!: any;

  mounted(): void {
    this.observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        this.onEnter();
      }
    });

    this.observer.observe(this.wrapper);
  }

  unmounted(): void {
    this.observer.disconnect();
  }

  onEnter(): void {
    // Image is visible (means: has entered the viewport),
    // so start loading by setting the src attribute
    if (this.image) {
      this.image.src = this.src;

      this.image.onload = () => {
        // Image is loaded, so start fading in
        this.isLoaded = true;
      };
    }
  }

  @Watch("src")
  updateImageWithSrcChange(): void {
    this.onEnter();
  }
}
