














































































































































































































































































import { Component, Prop } from "vue-property-decorator";
import DateCalendarIcon from "@/components/Event/DateCalendarIcon.vue";
import { mixins } from "vue-class-component";
import { RawLocation, Route } from "vue-router";
import { EventStatus, EventVisibility, ParticipantRole } from "@/types/enums";
import { IParticipant } from "../../types/participant.model";
import {
  IEventCardOptions,
  organizer,
  organizerDisplayName,
} from "../../types/event.model";
import { displayNameAndUsername, IActor, IPerson } from "../../types/actor";
import ActorMixin from "../../mixins/actor";
import { CURRENT_ACTOR_CLIENT } from "../../graphql/actor";
import EventMixin from "../../mixins/event";
import RouteName from "../../router/name";
import { changeIdentity } from "../../utils/auth";
import PopoverActorCard from "../Account/PopoverActorCard.vue";
import LazyImageWrapper from "@/components/Image/LazyImageWrapper.vue";
import InlineAddress from "@/components/Address/InlineAddress.vue";
import { PropType } from "vue";

const defaultOptions: IEventCardOptions = {
  hideDate: true,
  loggedPerson: false,
  hideDetails: false,
  organizerActor: null,
  memberofGroup: false,
};

@Component({
  components: {
    DateCalendarIcon,
    PopoverActorCard,
    LazyImageWrapper,
    InlineAddress,
  },
  apollo: {
    currentActor: {
      query: CURRENT_ACTOR_CLIENT,
    },
  },
})
export default class EventParticipationCard extends mixins(
  ActorMixin,
  EventMixin
) {
  /**
   * The participation associated
   */
  @Prop({ required: true, type: Object as PropType<IParticipant> })
  participation!: IParticipant;

  /**
   * Options are merged with default options
   */
  @Prop({ required: false, default: () => defaultOptions })
  options!: IEventCardOptions;

  currentActor!: IPerson;

  ParticipantRole = ParticipantRole;

  EventVisibility = EventVisibility;

  displayNameAndUsername = displayNameAndUsername;

  organizerDisplayName = organizerDisplayName;

  organizer = organizer;

  RouteName = RouteName;

  EventStatus = EventStatus;

  get mergedOptions(): IEventCardOptions {
    return { ...defaultOptions, ...this.options };
  }

  /**
   * Delete the event
   */
  async openDeleteEventModalWrapper(): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    await this.openDeleteEventModal(this.participation.event);
  }

  async gotToWithCheck(
    participation: IParticipant,
    route: RawLocation
  ): Promise<Route> {
    if (
      participation.actor.id !== this.currentActor.id &&
      participation.event.organizerActor
    ) {
      const organizerActor = participation.event.organizerActor as IPerson;
      await changeIdentity(this.$apollo.provider.defaultClient, organizerActor);
      this.$buefy.notification.open({
        message: this.$t(
          "Current identity has been changed to {identityName} in order to manage this event.",
          {
            identityName: organizerActor.preferredUsername,
          }
        ) as string,
        type: "is-info",
        position: "is-bottom-right",
        duration: 5000,
      });
    }
    return this.$router.push(route);
  }

  get organizerActor(): IActor | undefined {
    if (
      this.participation.event.attributedTo &&
      this.participation.event.attributedTo.id
    ) {
      return this.participation.event.attributedTo;
    }
    return this.participation.event.organizerActor;
  }

  get seatsLeft(): number | null {
    if (this.participation.event.options.maximumAttendeeCapacity > 0) {
      return (
        this.participation.event.options.maximumAttendeeCapacity -
        this.participation.event.participantStats.participant
      );
    }
    return null;
  }

  get lastSeatsLeft(): boolean {
    if (this.seatsLeft) {
      return this.seatsLeft < 10;
    }
    return false;
  }
}
