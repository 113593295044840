
















import { Component, Prop, Vue } from "vue-property-decorator";
import RouteName from "../router/name";

@Component
export default class SearchField extends Vue {
  @Prop({ type: String, required: false }) placeholder!: string;

  search = "";

  async enter(): Promise<void> {
    this.$emit("navbar-search");
    await this.$router.push({
      name: RouteName.SEARCH,
      query: { term: this.search },
    });
  }

  get defaultPlaceHolder(): string {
    // We can't use "this" inside @Prop's default value.
    return this.placeholder || (this.$t("Search") as string);
  }
}
