

























import { Component, Vue, Prop } from "vue-property-decorator";
import { IActor, usernameWithDomain } from "../../types/actor";

@Component
export default class ActorCard extends Vue {
  @Prop({ required: true, type: Object }) actor!: IActor;

  @Prop({ required: false, type: Boolean, default: false }) full!: boolean;

  @Prop({ required: false, type: Boolean, default: false }) popover!: boolean;

  @Prop({ required: false, type: Boolean, default: true }) limit!: boolean;

  usernameWithDomain = usernameWithDomain;
}
