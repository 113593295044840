










import { IEvent } from "@/types/event.model";
import { PropType } from "vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import EventCard from "./EventCard.vue";
@Component({
  components: {
    EventCard,
  },
})
export default class MultiCard extends Vue {
  @Prop({ type: Array as PropType<IEvent[]>, required: true })
  events!: IEvent[];
}
