




import { decode } from "blurhash";
import { Component, Prop, Ref, Vue } from "vue-property-decorator";

@Component
export default class BlurhashImg extends Vue {
  @Prop({ type: String, required: true }) hash!: string;
  @Prop({ type: Number, default: 1 }) aspectRatio!: string;

  @Ref("canvas") readonly canvas!: any;

  mounted(): void {
    try {
      const pixels = decode(this.hash, 32, 32);
      const imageData = new ImageData(pixels, 32, 32);
      const context = this.canvas.getContext("2d");
      context.putImageData(imageData, 0, 0);
    } catch (e) {
      console.error(e);
    }
  }
}
