




































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { EventSortField, ParticipantRole, SortDirection } from "@/types/enums";
import { Paginate } from "@/types/paginate";
import { supportsWebPFormat } from "@/utils/support";
import { IParticipant, Participant } from "../types/participant.model";
import { FETCH_EVENTS } from "../graphql/event";
import EventParticipationCard from "../components/Event/EventParticipationCard.vue";
import MultiCard from "../components/Event/MultiCard.vue";
import { CURRENT_ACTOR_CLIENT } from "../graphql/actor";
import { IPerson, Person } from "../types/actor";
import {
  ICurrentUser,
  IUser,
  IUserSettings,
} from "../types/current-user.model";
import { CURRENT_USER_CLIENT } from "../graphql/user";
import { CLOSE_CONTENT, HOME_USER_QUERIES } from "../graphql/home";
import RouteName from "../router/name";
import { IEvent } from "../types/event.model";
import DateComponent from "../components/Event/DateCalendarIcon.vue";
import { CONFIG } from "../graphql/config";
import { IConfig } from "../types/config.model";
import { IFollowedGroupEvent } from "../types/followedGroupEvent.model";
import Subtitle from "../components/Utils/Subtitle.vue";

@Component({
  apollo: {
    events: {
      query: FETCH_EVENTS,
      variables: {
        orderBy: EventSortField.INSERTED_AT,
        direction: SortDirection.DESC,
      },
    },
    currentActor: {
      query: CURRENT_ACTOR_CLIENT,
      update: (data) => new Person(data.currentActor),
    },
    currentUser: CURRENT_USER_CLIENT,
    config: CONFIG,
    closeContent: {
      query: CLOSE_CONTENT,
      variables() {
        return {
          location: this.loggedUser?.settings?.location?.geohash,
          radius: this.loggedUser?.settings?.location?.range,
        };
      },
      update(data) {
        this.closeEvents = data.searchEvents;
      },
      skip() {
        return (
          !this.currentUser?.isLoggedIn ||
          !this.loggedUser?.settings?.location?.geohash ||
          !this.loggedUser?.settings?.location?.range
        );
      },
    },
    userQueries: {
      query: HOME_USER_QUERIES,
      update(data) {
        console.log("loggedUser", data.loggedUser);
        this.loggedUser = data.loggedUser;
        this.followedGroupEvents = data.loggedUser.followedGroupEvents;
        this.currentUserParticipations =
          data.loggedUser.participations.elements.map(
            (participation: IParticipant) => new Participant(participation)
          );
      },
      variables: {
        afterDateTime: new Date().toISOString(),
      },
      skip() {
        return !this.currentUser?.isLoggedIn;
      },
    },
  },
  components: {
    Subtitle,
    DateComponent,
    EventParticipationCard,
    MultiCard,
  },
  metaInfo() {
    return {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      title: this.instanceName,
      titleTemplate: "%s | Mobilizon",
    };
  },
})
export default class Home extends Vue {
  events: Paginate<IEvent> = {
    elements: [],
    total: 0,
  };

  locations = [];

  city = { name: null };

  country = { name: null };

  currentUser!: ICurrentUser;

  loggedUser: IUser | null = null;

  currentActor!: IPerson;

  config!: IConfig;

  RouteName = RouteName;

  currentUserParticipations: IParticipant[] = [];

  supportsWebPFormat = supportsWebPFormat;

  closeEvents: Paginate<IEvent> = { elements: [], total: 0 };

  followedGroupEvents: Paginate<IFollowedGroupEvent> = {
    elements: [],
    total: 0,
  };

  // get displayed_name() {
  //   return this.loggedPerson && this.loggedPerson.name === null
  //     ? this.loggedPerson.preferredUsername
  //     : this.loggedPerson.name;
  // }

  get instanceName(): string | undefined {
    if (!this.config) return undefined;
    return this.config.name;
  }

  // eslint-disable-next-line class-methods-use-this
  get welcomeBack(): boolean {
    return window.localStorage.getItem("welcome-back") === "yes";
  }

  // eslint-disable-next-line class-methods-use-this
  get newRegisteredUser(): boolean {
    return window.localStorage.getItem("new-registered-user") === "yes";
  }

  thisWeek(
    row: [string, Map<string, IParticipant>]
  ): Map<string, IParticipant> {
    if (this.isInLessThanSevenDays(row[0])) {
      return row[1];
    }
    return new Map();
  }

  // eslint-disable-next-line class-methods-use-this
  mounted(): void {
    if (window.localStorage.getItem("welcome-back")) {
      window.localStorage.removeItem("welcome-back");
    }
    if (window.localStorage.getItem("new-registered-user")) {
      window.localStorage.removeItem("new-registered-user");
    }
  }

  // eslint-disable-next-line class-methods-use-this
  isToday(date: Date): boolean {
    return new Date(date).toDateString() === new Date().toDateString();
  }

  isTomorrow(date: string): boolean {
    return this.isInDays(date, 1);
  }

  isInDays(date: string, nbDays: number): boolean {
    return this.calculateDiffDays(date) === nbDays;
  }

  isBefore(date: string, nbDays: number): boolean {
    return this.calculateDiffDays(date) < nbDays;
  }

  isAfter(date: string, nbDays: number): boolean {
    return this.calculateDiffDays(date) >= nbDays;
  }

  isInLessThanSevenDays(date: string): boolean {
    return this.isBefore(date, 7);
  }

  // eslint-disable-next-line class-methods-use-this
  calculateDiffDays(date: string): number {
    return Math.ceil(
      (new Date(date).getTime() - new Date().getTime()) / 1000 / 60 / 60 / 24
    );
  }

  get thisWeekGoingToEvents(): IParticipant[] {
    const res = this.currentUserParticipations.filter(
      ({ event, role }) =>
        event.beginsOn != null &&
        this.isAfter(event.beginsOn.toDateString(), 0) &&
        this.isBefore(event.beginsOn.toDateString(), 7) &&
        role !== ParticipantRole.REJECTED
    );
    res.sort(
      (a: IParticipant, b: IParticipant) =>
        a.event.beginsOn.getTime() - b.event.beginsOn.getTime()
    );
    return res;
  }

  get goingToEvents(): Map<string, Map<string, IParticipant>> {
    return this.thisWeekGoingToEvents.reduce(
      (
        acc: Map<string, Map<string, IParticipant>>,
        participation: IParticipant
      ) => {
        const day = new Date(participation.event.beginsOn).toDateString();
        const participations: Map<string, IParticipant> =
          acc.get(day) || new Map();
        participations.set(
          `${participation.event.uuid}${participation.actor.id}`,
          participation
        );
        acc.set(day, participations);
        return acc;
      },
      new Map()
    );
  }

  eventDeleted(eventid: string): void {
    this.currentUserParticipations = this.currentUserParticipations.filter(
      (participation) => participation.event.id !== eventid
    );
  }

  viewEvent(event: IEvent): void {
    this.$router.push({ name: RouteName.EVENT, params: { uuid: event.uuid } });
  }

  @Watch("loggedUser")
  detectEmptyUserSettings(loggedUser: IUser): void {
    console.debug("Try to detect empty user settings", loggedUser);
    if (loggedUser?.id && loggedUser?.settings === null) {
      console.debug("No user settings, pushing to onboarding assistant");
      this.$router.push({
        name: RouteName.WELCOME_SCREEN,
        params: { step: "1" },
      });
    }
  }

  get loggedUserSettings(): IUserSettings | undefined {
    return this.loggedUser?.settings;
  }

  get canShowMyUpcomingEvents(): boolean {
    return this.currentActor.id != undefined && this.goingToEvents.size > 0;
  }

  get canShowCloseEvents(): boolean {
    return (
      this.loggedUser?.settings?.location != undefined &&
      this.closeEvents.total > 0
    );
  }

  get canShowFollowedGroupEvents(): boolean {
    return this.filteredFollowedGroupsEvents.length > 0;
  }

  get filteredFollowedGroupsEvents(): IEvent[] {
    return this.followedGroupEvents.elements
      .map(({ event }: { event: IEvent }) => event)
      .filter(
        ({ id }) =>
          !this.thisWeekGoingToEvents
            .map(({ event: { id: event_id } }) => event_id)
            .includes(id)
      )
      .slice(0, 4);
  }
}
