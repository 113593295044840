























































































import {
  IEvent,
  IEventCardOptions,
  organizerDisplayName,
  organizer,
} from "@/types/event.model";
import { Component, Prop, Vue } from "vue-property-decorator";
import DateCalendarIcon from "@/components/Event/DateCalendarIcon.vue";
import LazyImageWrapper from "@/components/Image/LazyImageWrapper.vue";
import { Actor, Person } from "@/types/actor";
import { EventStatus, ParticipantRole } from "@/types/enums";
import RouteName from "../../router/name";
import InlineAddress from "@/components/Address/InlineAddress.vue";

@Component({
  components: {
    DateCalendarIcon,
    LazyImageWrapper,
    InlineAddress,
  },
})
export default class EventCard extends Vue {
  @Prop({ required: true }) event!: IEvent;

  @Prop({ required: false }) options!: IEventCardOptions;

  ParticipantRole = ParticipantRole;

  EventStatus = EventStatus;

  RouteName = RouteName;

  organizerDisplayName = organizerDisplayName;

  organizer = organizer;

  defaultOptions: IEventCardOptions = {
    hideDate: false,
    loggedPerson: false,
    hideDetails: false,
    organizerActor: null,
    memberofGroup: false,
  };

  get mergedOptions(): IEventCardOptions {
    return { ...this.defaultOptions, ...this.options };
  }

  get actor(): Actor {
    return Object.assign(
      new Person(),
      this.event.organizerActor || this.mergedOptions.organizerActor
    );
  }
}
