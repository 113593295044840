
























































































































































































import { Component, Ref, Vue, Watch } from "vue-property-decorator";
import Logo from "@/components/Logo.vue";
import { GraphQLError } from "graphql";
import { loadLanguageAsync } from "@/utils/i18n";
import { ICurrentUserRole } from "@/types/enums";
import { CURRENT_USER_CLIENT, USER_SETTINGS } from "../graphql/user";
import { changeIdentity, logout } from "../utils/auth";
import {
  CURRENT_ACTOR_CLIENT,
  IDENTITIES,
  UPDATE_DEFAULT_ACTOR,
} from "../graphql/actor";
import { displayName, IPerson, Person } from "../types/actor";
import { CONFIG } from "../graphql/config";
import { IConfig } from "../types/config.model";
import { ICurrentUser, IUser } from "../types/current-user.model";
import SearchField from "./SearchField.vue";
import RouteName from "../router/name";

@Component({
  apollo: {
    currentUser: CURRENT_USER_CLIENT,
    currentActor: CURRENT_ACTOR_CLIENT,
    identities: {
      query: IDENTITIES,
      update: ({ identities }) =>
        identities
          ? identities.map((identity: IPerson) => new Person(identity))
          : [],
      skip() {
        return this.currentUser.isLoggedIn === false;
      },
      error({ graphQLErrors }) {
        this.handleErrors(graphQLErrors);
      },
    },
    config: CONFIG,
    loggedUser: {
      query: USER_SETTINGS,
      skip() {
        return !this.currentUser || this.currentUser.isLoggedIn === false;
      },
    },
  },
  components: {
    Logo,
    SearchField,
  },
})
export default class NavBar extends Vue {
  currentActor!: IPerson;

  config!: IConfig;

  currentUser!: ICurrentUser;

  loggedUser!: IUser;

  ICurrentUserRole = ICurrentUserRole;

  identities: IPerson[] = [];

  RouteName = RouteName;

  mobileNavbarActive = false;

  displayName = displayName;

  @Ref("user-dropdown") userDropDown!: any;

  toggleMenu(): void {
    console.debug("called toggleMenu");
    this.userDropDown.showMenu();
  }

  @Watch("currentActor")
  async initializeListOfIdentities(): Promise<void> {
    if (!this.currentUser.isLoggedIn) return;
    const { data } = await this.$apollo.query<{ identities: IPerson[] }>({
      query: IDENTITIES,
    });
    if (data) {
      this.identities = data.identities.map(
        (identity: IPerson) => new Person(identity)
      );

      // If we don't have any identities, the user has validated their account,
      // is logging for the first time but didn't create an identity somehow
      if (this.identities.length === 0) {
        console.debug(
          "We have no identities listed for current user",
          this.identities
        );
        console.debug("Pushing route to REGISTER_PROFILE");
        try {
          await this.$router.push({
            name: RouteName.REGISTER_PROFILE,
            params: {
              email: this.currentUser.email,
              userAlreadyActivated: "true",
            },
          });
        } catch (err) {
          return undefined;
        }
      }
    }
  }

  @Watch("loggedUser")
  setSavedLanguage(): void {
    if (this.loggedUser?.locale) {
      console.debug("Setting locale from navbar");
      loadLanguageAsync(this.loggedUser.locale);
    }
  }

  async handleErrors(errors: GraphQLError[]): Promise<void> {
    if (
      errors.length > 0 &&
      errors[0].message ===
        "You need to be logged-in to view your list of identities"
    ) {
      await this.logout();
    }
  }

  async logout(): Promise<void> {
    await logout(this.$apollo.provider.defaultClient);
    this.$buefy.notification.open({
      message: this.$t("You have been disconnected") as string,
      type: "is-success",
      position: "is-bottom-right",
      duration: 5000,
    });

    if (this.$route.name === RouteName.HOME) return;
    await this.$router.push({ name: RouteName.HOME });
  }

  async setIdentity(identity: IPerson): Promise<void> {
    await this.$apollo.mutate({
      mutation: UPDATE_DEFAULT_ACTOR,
      variables: {
        preferredUsername: identity.preferredUsername,
      },
    });
    return changeIdentity(this.$apollo.provider.defaultClient, identity);
  }

  get hideCreateEventsButton(): boolean {
    return !!this.config?.restrictions?.onlyGroupsCanCreateEvents;
  }
}
