















































































import { Component, Vue, Watch } from "vue-property-decorator";
import { saveLocaleData } from "@/utils/auth";
import { loadLanguageAsync } from "@/utils/i18n";
import RouteName from "../router/name";
import langs from "../i18n/langs.json";

@Component
export default class Footer extends Vue {
  RouteName = RouteName;

  locale: string | null = this.$i18n.locale;

  langs: Record<string, string> = langs;

  // eslint-disable-next-line class-methods-use-this
  get random(): number {
    return Math.floor(Math.random() * 4) + 1;
  }

  @Watch("locale")
  // eslint-disable-next-line class-methods-use-this
  async updateLocale(locale: string): Promise<void> {
    if (locale) {
      console.debug("Setting locale from footer");
      await loadLanguageAsync(locale);
      saveLocaleData(locale);
    }
  }

  @Watch("$i18n.locale", { deep: true })
  updateLocaleFromI18n(locale: string): void {
    if (locale) {
      this.locale = locale;
    }
  }

  isLangSelected(lang: string): boolean {
    return lang === this.locale;
  }
}
