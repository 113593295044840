



















import { IAddress } from "@/types/address.model";
import { PropType } from "vue";
import { Prop, Vue, Component } from "vue-property-decorator";

@Component
export default class InlineAddress extends Vue {
  @Prop({ required: true, type: Object as PropType<IAddress> })
  physicalAddress!: IAddress;

  get isDescriptionDifferentFromLocality(): boolean {
    return (
      this.physicalAddress?.description !== this.physicalAddress?.locality &&
      this.physicalAddress?.description !== undefined
    );
  }
}
